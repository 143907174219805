export default {
    computed: {
        siteTitle: function() {
            return this.dataStore.getStore().name
        },
        pageTitle: function () {
            return this.defaultPageTitle
        },
        defaultPageTitle: function () {
            return this.$route.meta.title
        },
        pageDescription: function () {
            return this.defaultPageDescription
        },
        defaultPageDescription: function () {
            return this.dataStore.getStore().description
        }
    }
}