import {reactive} from 'vue'

export default {
    state: reactive({
        token: null,
        store: null,
        site: null,
        channels: null,
        basket: null,
        page: null,
        partner: null
    }),
    setStore(store) {
        this.state.store = store
    },
    getStore() {
        return this.state.store
    },
    setChannels(channels) {
        this.state.channels = channels
    },
    getChannels() {
        return this.state.channels
    },
    getChannel(name) {
        const channel = this.state.channels.filter(channel => channel.channel_type.name === name);

        if (channel.length > 0) {
            return channel[0];
        }

        return null;
    },
    getSite() {
        return this.getChannel('Catalog')
    },
    getPay() {
        return this.getChannel('Pay')
    },
    setBasket(basket) {
        sessionStorage.setItem('sc_basket_reference', basket.basket_reference)
        this.state.basket = basket
    },
    getBasket() {
        return this.state.basket
    },
    getBasketReference() {
        return sessionStorage.getItem('sc_basket_reference')
    },
    setPage(page) {
        this.state.page = page
    },
    getPage() {
        return this.state.page
    },
    setApiToken(token) {
        sessionStorage.setItem('sc_api_token', token.api_token)
        sessionStorage.setItem('sc_api_refresh_token', token.refresh_token)
        this.state.token = token
    },
    getApiToken() {
        return sessionStorage.getItem('sc_api_token')
    },
    setUser(user) {
        sessionStorage.setItem('sc_user', JSON.stringify(user))
    },
    getUser() {
        const user = sessionStorage.getItem('sc_user')
        return user ? JSON.parse(user) : null
    },
    setPartner(partner) {
        this.state.partner = partner
    },
    getPartner() {
        return this.state.partner
    },
    getRecentlyViewed() {
        const viewed = sessionStorage.getItem('sc_recently_viewed')
        if (viewed) {
            return viewed.split(',')
        }
        return []
    },
    setRecentlyViewed(product) {
        let viewed = this.getRecentlyViewed()

        if(viewed && !viewed.includes(product.id.toString())){
            viewed.unshift(product.id)
        }

        sessionStorage.setItem('sc_recently_viewed', viewed.join(','))
    },
    loggedIn() {
        return this.getUser() !== null
    },
    logout() {
        sessionStorage.setItem('sc_user', null)
    },
    setPartnerReference(partnerReference) {
        sessionStorage.setItem('sc_partner_reference', partnerReference)
    },
    getPartnerReference() {
        return sessionStorage.getItem('sc_partner_reference')
    }
}