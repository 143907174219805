import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import './index.css'
import '../node_modules/aos/dist/aos.css'
//import '@formkit/themes/genesis'
import {ApiWrapper, Config} from '@sugarcoat/js-sdk'
import dataStore from './store/data'
import errorHandler from './mixins/errorHandler'
import mitt from 'mitt'
import axios from 'axios'
const emitter = mitt()
import * as Sentry from "@sentry/vue"
import {createHead} from '@vueuse/head'
import { plugin, defaultConfig } from '@formkit/vue'

const nestedRouting = import.meta.env.VITE_APP_ROUTE_TYPE === 'nested'
const storeSlug = nestedRouting ? window.location.host.split('.')[0] : null

Config.configure({
    baseUrl: import.meta.env.VITE_APP_API_BASE_URL,
    key: import.meta.env.VITE_APP_API_PUBLIC_KEY
})

Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN
})

const head = createHead()
const sdk = new ApiWrapper()

// Load the store and channels before the app loads to boot with state
const initialise = () => {
    sdk.generateToken(storeSlug).then(response => {
        dataStore.setApiToken(response.token);
    }).then(response => {
        return axios.all([setupStore(), setupChannels()])
    }).catch(error => {
        errorHandler.handleError(error);
        window.location.href = '/404'
        return
    }).finally(() => {
        initApp()
    })
}

const setupStore = async () => {
    await sdk.getStore(['features']).then(response => {
        if (response.store !== null)
            dataStore.setStore(response.store)
    }).catch(error => {
        errorHandler.handleError(error);
        return
    })
}

const setupChannels = async () => {
    await sdk.getChannels().then(response => {
        if (response.channels.length > 0)
            dataStore.setChannels(response.channels);
    }).catch(error => {
        errorHandler.handleError(error);
        return
    })
}

const initApp = () => {
    const app = createApp(App, {sdk: sdk})

    app.use(head)

    app.provide('sdk', sdk)
    app.provide('dataStore', dataStore)
    app.provide('emitter', emitter)
    app.provide('errorHandler', errorHandler)

    app.config.errorHandler = (err) => {
        Sentry.captureException(err)
    }

    app.use(router)

    app.use(plugin, defaultConfig({}))

    app.config.globalProperties.window = window
    app.config.globalProperties.env = import.meta.env

    router.beforeResolve(async (to) => {
        let apiToken = dataStore.getApiToken()

        if (apiToken) {
            await sdk.getTokenByToken(apiToken)
                .then(response => {
                    const apiToken = response.token;
                    dataStore.setApiToken(apiToken);
                }).catch(error => {
                    errorHandler.handleError(error);
                })
            return
        }

        if (!apiToken) {
            await sdk.generateToken(storeSlug)
                .then(response => {
                    const apiToken = response.token;
                    dataStore.setApiToken(apiToken);
                }).catch(error => {
                    errorHandler.handleError(error);
                    window.location.href = '/404'
                    return
                })
        }
    })

    router.isReady().then(() => {
        app.mount('#app')
    })
}

initialise()
