import {defineAsyncComponent} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import dataStore from '../store/data'

const ProductListPage = defineAsyncComponent(() => import('../pages/ProductListPage.vue'))
const ProductPage = defineAsyncComponent(() => import('../pages/ProductPage.vue'))
const CollectionPage = defineAsyncComponent(() => import('../pages/CollectionPage.vue'))
const BasketPage = defineAsyncComponent(() => import('../pages/BasketPage.vue'))
const SupportPage = defineAsyncComponent(() => import('../pages/SupportPage.vue'))
const ProfileListPage = defineAsyncComponent(() => import('../pages/ProfileListPage.vue'))
const ProfilePage = defineAsyncComponent(() => import('../pages/ProfilePage.vue'))

const defaultPageGuard = function (to, from, next) {
    if (dataStore.getSite()) {
        next('/products')
    } else if (dataStore.getPay()) {
        next('/pay')
    } else {
        next('/404')
    }
}

const siteGuard = function (to, from, next) {
    if (dataStore.getSite()) {
        next();
    } else {
        next('/')
    }
}

const payGuard = function (to, from, next) {
    if (dataStore.getPay()) {
        next();
    } else {
        next('/')
    }
}

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {title: 'Home'},
        beforeEnter: defaultPageGuard
    },
    {
        path: '/profiles',
        name: 'profiles',
        component: ProfileListPage,
        meta: {title: 'Profiles'}
    },
    {
        path: '/profile/:username?',
        name: 'profile',
        component: ProfilePage,
        meta: {title: 'Profile'}
    },
    {
        path: '/products/:type?',
        name: 'products',
        component: ProductListPage,
        meta: {title: 'Products'},
        beforeEnter : siteGuard,
    },
    {
        path: '/products/:category/:option',
        name: 'product-categories',
        component: ProductListPage,
        meta: {title: 'Category'},
        beforeEnter : siteGuard,
    },
    {
        path: '/product/:slug',
        name: 'product',
        component: ProductPage,
        meta: {title: 'Product'},
        beforeEnter : siteGuard,
    },
    {
        path: '/collection/:slug',
        name: 'collection',
        component: CollectionPage,
        meta: {title: 'Collection'},
        beforeEnter : siteGuard,
    },
    {
        path: '/basket',
        name: 'basket',
        component: BasketPage,
        meta: {title: 'Basket'},
        beforeEnter : siteGuard,
    },
    {
        path: '/pay',
        name: 'pay',
        component: SupportPage,
        meta: {title: 'Support'},
        beforeEnter: payGuard
    },
    {
        path: '/checkout/:slug/:basket_reference',
        name: 'checkout',
        beforeEnter(to, from, next) {
            window.location.href = import.meta.env.VITE_APP_CHECKOUT_URL + '/' + to.params.slug + '/' + to.params.basket_reference
        },
    },
    {
        path: '/404',
        name: '404',
        beforeEnter(to, from, next) {
            window.location.href = '/404.html'
        }
    },
    {
        path: '/:catchAll(.*)',
        name: 'catchall',
        redirect: to => {
            return { name: 'home'}
        },
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {top: 0}
    }
})
export default router